<template>
  <div class="entity-container">
    <div class="search-bar">
      <h2>Personal Projects</h2>
      <input type="search" placeholder="Software" v-model="searchProject" />
    </div>
    <div class="project-body">
      <div v-if="projects.length">
        <div
          v-for="(project, index) of projects"
          :key="project.title"
          class="project-box"
        >
          <div
            class="project-image"
            :class="project.id"
            @mouseover="isHoveringImage[index] = true"
            @mouseout="isHoveringImage[index] = false"
            @click="project.previewPhotos && openPreview(project.id)"
          >
            <div v-show="isHoveringImage[index]" class="project-title">
              <span>{{ project.title }}</span>
            </div>
            <div class="media-boxes">
              <div class="flex-center">
                <span>{{ project.title }}</span>
              </div>
              <div class="project-footer">
                <div class="technologies">
                  <img
                    v-for="t in project.technologies"
                    :key="t"
                    :src="imageUrl(t)"
                    :alt="t"
                    :title="t"
                  />
                </div>
                <div class="links">
                  <a
                    v-if="project.github"
                    rel="noopener"
                    :href="project.github.link"
                  >
                    <img src="@/assets/socials/github.svg" alt="" width="19" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="specs">
            <span class="title">{{ project.title }}</span>
            <p>
              <span v-for="(p, index) in project.description" :key="index">
                {{ p }}<br />
              </span>
            </p>
            <div class="project-footer">
              <div class="technologies">
                <img
                  v-for="t in project.technologies"
                  :key="t"
                  :src="imageUrl(t)"
                  :alt="t"
                  :title="t"
                />
              </div>
              <div class="links">
                <a
                  v-if="project.github"
                  rel="noopener"
                  :href="project.github.link"
                >
                  <img src="@/assets/socials/github.svg" alt="" width="21" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="project-box">No results found</div>
      </div>
    </div>

    <ProjectPreview
      v-if="isPreviewOpened"
      :folderName="projectId"
      @closePreview="closePreview"
    />
  </div>
</template>

<script>
import { computed, ref, reactive } from "vue";

import { projects } from "./projects";
import ProjectPreview from "./ProjectPreview.vue";

export default {
  components: { ProjectPreview },
  name: "Projects",
  setup() {
    const searchProject = ref("");
    const isPreviewOpened = ref(false);
    const projectId = ref("");
    const isHoveringImage = reactive([]);

    const filteredProjects = computed(() => {
      const searchInput = searchProject.value.toLowerCase();

      if (!searchInput.trim()) return projects;

      return projects.filter((p) => {
        const isTitleMatch = p.title.toLowerCase().includes(searchInput);
        const isTechnologyMatch = p.technologies.find((el) =>
          el.toLowerCase().includes(searchInput)
        );

        return isTitleMatch || isTechnologyMatch;
      });
    });

    const imageUrl = (lang) => {
      let iconName;

      switch (lang) {
        case "React.js":
          iconName = "react";
          break;
        case "Vue.js":
          iconName = "vue";
          break;
        case "Svelte.js":
          iconName = "svelte";
          break;
        case "Node.js":
          iconName = "node";
          break;
        default:
          iconName = lang.toLowerCase();
          break;
      }

      return require(`@/assets/tech/${iconName}.svg`);
    };

    const openPreview = (pid) => {
      projectId.value = pid;
      isPreviewOpened.value = true;
    };

    const closePreview = () => {
      projectId.value = "";
      isPreviewOpened.value = false;
    };

    return {
      projects: filteredProjects,
      searchProject,
      isHoveringImage,
      imageUrl,

      projectId,
      isPreviewOpened,
      openPreview,
      closePreview,
    };
  },
};
</script>

<style lang="sass" scoped>
$white: #fff
$offWhite: rgba(255, 255, 255, 0.25)
$calmWhite: #E5E5E5
$titleColor: #291A1D
$stageDurationColor: #C4C4C4
$black: #222222

@mixin flex-between
  display: flex
  justify-content: space-between

@mixin scroll-styles
  overflow: overlay

  &::-webkit-scrollbar
    background-color: transparent
    display: block
    width: 6px

  &::-webkit-scrollbar-track
    background-color: transparent

  &::-webkit-scrollbar-thumb
    background-color: $calmWhite
    border: 2px solid transparent
    border-radius: 100px

@mixin title-project
  text-align: center
  font-size: 1em

.entity-container
  .search-bar
    @include flex-between
    flex-wrap: wrap
    align-items: center
    padding: 1rem
    box-shadow: 0px 4px 12px #44A5D9

    h2
      font-size: 1.3em
      color: $titleColor
      padding: 0.5rem 0

    input
      padding: 6px 8px
      background: $white
      border: 2px solid $white
      border-radius: 20px
      max-width: 200px
      outline: unset

      &:focus, &:hover, &:active
        background: $white

  .project-body
    @include scroll-styles
    max-height: 50vh

    .project-box
      display: grid
      grid-template-columns: 40% auto
      padding: 1rem
      grid-gap: 1rem

      .project-image
        position: relative
        background-size: cover
        min-height: 160px
        border-radius: 8px
        overflow: hidden

        .project-title
          width: 100%
          height: 100%
          position: absolute
          background: rgba(217, 217, 38, 0.66)
          display: flex
          justify-content: center
          align-items: center
          padding: 4px
          border-radius: 4px
          cursor: pointer

          span
            @include title-project

      .specs
        @include flex-between
        flex-direction: column
        padding: 0 1rem

        .title
          color: $black

        p
          color: $white

.project-footer
  @include flex-between
  align-items: center
  margin-top: 1rem

  .technologies
    display: grid
    grid-gap: 10px
    grid-auto-flow: column

    img
      width: 20px

  .links, a
    @include flex-between
    align-items: center

    &:hover
      opacity: 0.7

.media-boxes
  display: none

// project images
.project-image
  background: url("../../../assets/images/projects/water.webp")
.crossword
  background: url("../../../assets/images/projects/crossword.webp")
.debugger
  background: url("../../../assets/images/projects/debugger.webp")
.bnb
  background: url("../../../assets/images/projects/bnb.webp")
.cinema_booking
  background: url("../../../assets/images/projects/movie_flash.webp")
.quiz
  background: url("../../../assets/images/projects/quiz.webp")

@media screen and (max-width: 750px)
  .specs p span
    font-size: 0.9em

@media screen and (max-width: 600px)
  .entity-container .project-body .project-box .specs,
  .entity-container .project-body .project-box .project-image .project-title
    display: none

  .entity-container .project-body .project-box
    grid-template-columns: auto
    grid-template-rows: auto

  .entity-container .search-bar input
    max-width: 140px

  .media-boxes
    display: block
    width: 100%
    height: 100%
    position: absolute
    background: #894747c7
    display: flex
    flex-direction: column
    justify-content: space-between

    .flex-center
      display: flex
      justify-content: center
      align-items: center
      padding: 4px
      height: 100%

      span
        @include title-project
        padding: 4px

    .project-footer
      bottom: 0
      padding: 4px

      .technologies
        grid-gap: 6px

        img
          width: 16px
</style>