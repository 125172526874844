<template>
  <footer>
    <div class="copyright">Copyright © 2020 - 2021</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="sass">
  $calmWhite: #E5E5E5
  $white: #fff2f2
  
  @mixin flex-center
    display: flex
    justify-content: center
    align-items: center

  footer
    width: 100%
    border-top: 1px solid $calmWhite
    @include flex-center()

    .copyright
      cursor: default
      user-select: none
      padding: 1rem
      width: 60%
      color: $white
      font-size: 0.8em
</style>