<template>
  <div>
    <div class="column">
      <div class="first">
        <span>{{ infos.first }}</span>
      </div>
      <div class="second">
        <span>{{ infos.second }}</span>
      </div>
      <div class="third">
        <span>{{ infos.third }}</span>
      </div>
      <div class="fourth">
        <span>{{ infos.fourth }}</span>
      </div>
      <div class="fifth">
        <span>{{ infos.fifth }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const infos = {
  first:
    "Also, I am still in progress learning, reading and searching about new technologies and better methods of doing things. That's why I love programming.",
  second:
    "My favorites frameworks are React.js and Vue.js. These two are the main languages I waste the most time in the past.",
  third:
    "I love reading about AI technologies.  As I build an application based on CSP algorithms and NLP, I keep in touch to find attractive projects and play more with them.",
  fourth:
    "Another approach in programming is PWA. I am very happy about this era of web apps.",
  fifth:
    "Lastly, with my love for design, I am learning UI / UX fundamentals to build more user-friendly applications.",
};

export default {
  name: "About",
  setup() {
    return {
      infos,
    };
  },
};
</script>

<style lang="scss" scoped>
$white: #e5e5e5;
$textColor: #291a1d;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  @include flex-center();
  flex-direction: column;
}

.column {
  @include flex-column();
  position: relative;
  background: #445964;
  padding: 1rem;
  width: 2rem;
  left: 40%;
  border-radius: 4px;
  margin: 2rem 0;

  div {
    position: relative;
    padding: 1rem 0.6rem;
    border-radius: 8px;
    font-size: 0.9em;
    margin: 0.4rem;

    span {
      color: $textColor;
    }
  }
}

.second span,
.fourth span {
  color: $white !important;
}

.first {
  background: #e5e5e5;
  width: 200px;
  transform: translateX(-100px);
}
.second {
  background: #6da6c1;
  width: 300px;
  transform: translateX(120px);
}
.third {
  background: #c4c4c4;
  width: 280px;
  transform: translateX(-100px);
}
.fourth {
  background: #483f3f;
  width: 160px;
  transform: translateX(80px);
}
.fifth {
  @include flex-center();
  background: #fff;
  width: 180px;
  height: 180px;
  transform: translateY(40px);
  border-radius: 50% !important;
  padding: 2rem !important;
}

@media screen and (max-width: 600px) {
  .column {
    left: 50%;
    margin-left: -1rem;

    div {
      transform: translateX(0);
    }
  }
}
</style>