export const projects = [
  {
    id: 'crossword',
    title: 'Automatic construction and crossword solving system',
    description: [
      `This is a crossword theme generator web application based on CSP problems (AI algorithm),
      where system generates an automatic crossword with answers and clues after user's input theme.`
    ],
    technologies: ['Python', 'Flask', 'JavaScript', 'Vue.js', 'SASS', 'HTML5', 'AI'],
    github: {
      link: 'https://github.com/spiroskarathanassis/crossword-web-app',
      name: 'crossword-web-app'
    },
  },
  {
    id: 'debugger',
    title: 'Advertising Debugger Extension',
    description: [
      `This application is a debugger chromium extension tool where can catch advertising requests,
      see the available webspaces on the DOM, handle ad bugs on publisher's web pages and access 
      information into ad elements, iframes and safeframes via chromium manifest communication.`
    ],
    technologies: ['React.js', 'JavaScript', 'SASS', 'HTML5'],
  },
  {
    id: 'bnb',
    title: 'BNB Bookings System - Administrator Panel',
    description: [
      `This PWA application is a booking system - administator panel where hosts can collaborate with sub-hosts, 
      add purchaces, bills, money transfers. Furthermore, has monthly and annually analytics and a new booking page.`
    ],
    technologies: ['Vue.js', 'Node.js', 'JavaScript', 'SASS', 'HTML5', 'Firebase', 'Postgresql'],
    previewPhotos: 8
  },
  {
    id: 'cinema_booking',
    title: 'Mobile Cinema Movie Booking',
    description: [
      `This is a cinema store where user can see details about next week movies as also book their seats.
      After booking user can see all active reservation history.`,
    ],
    technologies: ['Flutter', 'Firebase'],
    github: {
      link: 'https://github.com/spiroskarathanassis/movie-flash',
      name: 'movie-flash'
    },
  },
  {
    id: 'quiz',
    title: 'Quiz Game',
    description: [
      `It's a simple Quiz Game with final score of correct answers.`
    ],
    technologies: ['Svelte.js', 'JavaScript', 'SASS', 'HTML5', 'Firebase', 'Yarn'],
    github: {
      link: 'https://github.com/spiroskarathanassis/svelte-quiz-game',
      name: 'quiz-game'
    },
  },
];