<template>
  <h2>Skills</h2>

  <div class="skills-container">
    <div v-for="skill in skillList" :key="skill.img" class="skill-tab">
      <ul :class="skill.img">
        <h4>{{ skill.title }}</h4>
        <li v-for="skill in skill.lis" :key="skill">{{ skill }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { skills } from './skills';

export default {
  name: 'Skills',
  data () {
    return { skillList: skills }
  }
}
</script>

<style lang="scss">
  $white: #fff;
  $offWhite: rgba(255, 255, 255, 0.25);
  $calmWhite: #E5E5E5;
  $titleColor: #291A1D;
  $stageDurationColor: #C4C4C4;
  $black: #222222;

  @mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  } 
  @mixin flex-column {
    @include flex-center;
    justify-content: flex-start;
    flex-direction: column;
  }

  h2 {
    color: $white;
    text-align: center;
    padding: 1rem;
  }
  .skills-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 16px;
    margin: 2rem;

    .skill-tab {
      @include flex-center;
    }

    h4 {
      text-align: center;
      color: $titleColor;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    ul {
      @include flex-column();
      align-items: flex-start;
      background: $white;
      border-radius: 12px;
      padding: 1rem 2rem;;
      color: $stageDurationColor;
      max-height: 180px;
      width: 100%;
      height: 100%;

      li {
        font-family: 'Noto Sans JP';
        color: $black;
        // font-size: 0.9em;
        list-style-type: none;
      }
    }
    .hardware {
      background: 
        url("../../../assets/images/skills/hardware.png") no-repeat right 0 bottom 0,
        linear-gradient(90deg, #D83B3B 4.38%, rgba(216, 59, 59, 0) 4.54%),
        $white;
      background-size: 40% 100%, 100%, 100%;
    }
    .telephone {
      background: 
        url("../../../assets/images/skills/telephone.png") no-repeat right 0 bottom 0,
        linear-gradient(90deg, rgba(217, 217, 38, 0.66) 4.38%, rgba(216, 59, 59, 0) 4.54%),
        $white;
      background-size: 40% 100%, 100%, 100%;
    }
    .scissors {
      background: 
        url("../../../assets/images/skills/scissors.png") no-repeat right 0 bottom 0,
        linear-gradient(90deg, #69B7E1 4.38%, rgba(216, 59, 59, 0) 4.54%),
        $white;
      background-size: 40% 100%, 100%, 100%;
    }
    .laptop {
      background: 
        url("../../../assets/images/skills/laptop.png") no-repeat right 0 bottom 0,
        linear-gradient(90deg, rgba(78, 78, 239, 0.66) 4.38%, rgba(216, 59, 59, 0) 4.54%),
        $white;
      background-size: 40% 100%, 100%, 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .skills-container {
      grid-template-columns: 1fr;
    }
  }
</style>