export const bio = [
  {
    title: "Born",
    text: "I was born on a sunny spring day in May '95 in Patras, Greece and I still live here. I have two awesome siblings George and Marilyda and also two great parents. I am a self-motivated person who likes to set targets and get things done, regardless of difficulties and obstacles.",
    status: "start"
  },
  {
    title: "Education",
    text: "As a University of Patras graduate with a MSc in Computer Engineering and Informatics, I am a full-time software engineer, motivated by my love for learning and succeeding and I strive to become an outstanding and successful human in today’s society.",
    status: "middle"
  },
  {
    title: "Sports",
    text: "At the same time, through my passion for fitness, I got involved with many different (individual and team) sports, one of which was triathlon. Through very rigorous daily commitment and preparation, I was able to win lots of medals through the years which only made my passion even bigger.",
    status: "middle"
  },
  {
    title: "Career",
    text: "Another passion of mine is configuring and developing new software technologies. I now possess several skills that can allow me to evolve into a brilliant software engineer and be involved in great projects.",
    status: "middle"
  },
  {
    title: "Frontend Engineer • Phaistos Networks",
    duration: "Mar ‘20 - Mar ‘21",
    text: "As a frontend Engineer, I maintained, debugged, and optimized performance in an advertising ecosystem, while designing tests to ensure quality. I also led a team, handled urgent client tasks, and proposed solutions to enhance user experience, performance, and productivity.",
    status: "sub",
  },
  {
    title: "Frontend Engineer • Skroutz Last Mile",
    duration: "Apr ‘21 - Aug ‘22",
    text: "My role was to develop and optimize web and mobile apps using Vue.js and Flutter for both internal and client-facing delivery solutions. I worked in a fast-paced, growing company within a small, agile team.",
    status: "sub",
  },
  {
    title: "Frontend Engineer • Fieldcode",
    duration: "Aug ‘22 - Feb ‘23",
    text: "As a React Frontend Engineer in a Scrum team for a B2B tracking ticket system, I was responsible for designing and implementing new UI features for a large-scale web application.",
    status: "sub",
  },
  {
    title: "Frontend Engineer • Generation Y",
    duration: "May ‘23 - Nov ‘23",
    text: "I was enhanced the performance and user experience in an e-commerce platform by optimizing the existing application. I also modernized the legacy codebase by introducing and implementing new technologies, improving scalability and maintainability.",
    status: "sub",
  },
  {
    title: "Frontend Engineer • Kaizen Gaming",
    duration: "Dec ‘23 - Present",
    text: "",
    status: "sub",
  },
  {
    title: "Present",
    text: "I am currently working as a Frontend Engineer at an online betting company, specializing in testing, designing, optimizing, restructuring, and debugging scalable, high-performance applications. Our primary frontend framework is Vue.js, supported by Tailwind CSS for styling.",
    status: "finish"
  }
];