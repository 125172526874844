<template>
  <div class="preview">
    <div class="close-btn" @click="$emit('closePreview')">
      <img src="@/assets/images/icons/close_black_24dp.svg" alt="" width="16" />
    </div>

    <div class="preview-container">
      <button @click="previous">
        <img
          src="@/assets/images/icons/chevron_left_black_24dp.svg"
          alt="left"
        />
      </button>
      <div class="preview-img">
        <img :src="imageUrl()" alt="" />
      </div>
      <button @click="next">
        <img
          src="@/assets/images/icons/navigate_next_black_24dp.svg"
          alt="right"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "ProjectPreview",
  props: ["folderName"],
  setup(props) {
    const counter = ref(1);

    const imageUrl = () => {
      return require(`@/assets/images/projects/${props.folderName}/${counter.value}.webp`);
    };

    const lastImgIndex = computed(() => {
      switch (props.folderName) {
        case "bnb": {
          return 8;
        }
      }

      return 0;
    });

    const previous = () => {
      if (counter.value <= 1) {
        counter.value = 1;
      } else {
        counter.value -= 1;
      }
    };
    const next = () => {
      if (counter.value >= lastImgIndex.value) {
        counter.value = lastImgIndex.value;
      } else {
        counter.value += 1;
      }
    };

    return {
      // counter,
      previous,
      next,
      imageUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview {
  @include flex-center;
  z-index: 10;
  position: fixed;
  padding: 1rem;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.75);

  .close-btn {
    @include flex-center;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    margin: 1rem;
    overflow: hidden;
    border-radius: 4px;
    background: rgba(249, 249, 249, 0.35);
    cursor: pointer;
  }

  .preview-container {
    max-width: 800px;
    width: 100%;
    display: grid;
    grid-template-columns: 40px auto 40px;
    align-items: center;
    gap: 0.5rem;

    button {
      @include flex-center;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      border: none;
      cursor: pointer;
    }

    .preview-img {
      @include flex-center;
      border-radius: 1rem;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
}
</style>