export const skills = {
  work: {
    title: 'Work experience',
    lis: ['debugging', 'adaptive', 'productive'],
    img: 'hardware'
  },
  soft: {
    title: 'Soft Skills',
    lis: ['critical thinker', 'problem solving', 'communicative'],
    img: 'telephone'
  },
  hard: {
    title: 'Hard Skills',
    lis: ['Design (Figma, Adome XD)', 'Rest, GraphQL apis', 'Chrome Dev Tools', 'SEO Optimisation', 'Docker, Kubernetes'],
    img: 'scissors'
  },
  professional: {
    title: 'Professional Skills',
    lis: ['JavaScript, HTML, CSS', 'PHP, Python, Node', 'Vue, React, Svelte', 'MySQL, Postgres'],
    img: 'laptop'
  },
};