<template>
  <div class="sceen-img">
    <navigation-buttons isPage="bio" />
    <section>
      <div class="main-container">
        <div class="notes-container">
          <div class="bio-title">
            <h2>Mini Bio</h2>
          </div>	
          <div class="bio-story">

            <div v-for="(stage, index) in bio" :key="index" class="stage">
              <div class="point">
                <div :class="['circle', stage.status]">
                  <span :class="stage.status"></span>
                </div>
                <span :class="stage.status !== 'finish' && 'line'"></span>
              </div>
              <div class="description">
                <div>
                  <h4 :class="stage.status === 'sub' && 'sub-title'">{{ stage.title }}</h4>
                  <span v-if="stage.status === 'sub'">{{ stage.duration }}</span>
                </div>
                <div v-if="Array.isArray(stage.text)">
                  <p v-for="(t, i) in stage.text" :key="i">{{ t }}</p>
                </div>
                <p v-else>{{ stage.text }}</p>
              </div>
            </div>
            
          </div>
          <div class="bio-footer">
            <div class="resume">
              <a href="/Spiros_Karathanassis_CV.pdf" target="_blank">Resume</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="stages-preview">
      <div class="stage-line-container">
        <span class="line"></span>
      </div>
      <div class="stages">
        <span>Born</span>
        <span>Education</span>
        <span>Sports</span>
        <span>Career</span>
        <span>Present</span>
        <span>Resume</span>
        <span>Hobbies</span>
      </div>
    </div>
  </div>

  <div class="photos">
    <div class="left">
      <div class="img sport1"></div>
      <div class="img sport2"></div>
    </div>
    <div class="right">
      <div class="img sport3"></div>
      <div class="img sport4"></div>
    </div>
  </div>

  <section class="hobbies-section">
    <div class="hobbies">
      <div class="title-bar">
        <span>Hobbies</span>
      </div>
      <span class="line"></span>
    </div>

    <div class="flex-end">
      <div class="coffee">
        <img src="@/assets/images/hobbies.webp" alt="hobbies" width="400">
      </div>
    </div>
  </section>

  <Footer></Footer>
</template>

<script>
import NavigationButtons from '../sections/NavigationButtons.vue';
import Footer from '../sections/Footer.vue';

import { bio } from '../../store/constants/bio';

export default {
  name: 'BioPage',
  components: {
    NavigationButtons,
    Footer
  },
  data () {
    return {
      bio
    }
  }
}
</script>

<style lang="scss" scoped>
  $white: #fff;
  $calmWhite: #E5E5E5;
  $stageTitleColor: #291A1D;
  $stageDurationColor: #C4C4C4;
  $black: #222222;

  @mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  } 
  
  @mixin flex-column {
    @include flex-center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .sceen-img {
    background: url('../../assets/images/ballon.webp') no-repeat center 100%/cover;
    width: 100%;
    min-height: 100vh;
  }

  .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
  }

  .notes-container {
    margin: 2rem 1rem;
    padding: 1rem;
    background: linear-gradient(179.97deg, 
    rgba(105, 183, 225, 0.19) 3.88%,
    rgba(105, 183, 225, 0.4) 56.02%);
    backdrop-filter: blur(12px);
    border-radius: 20px;
    color: $calmWhite;
    z-index: 10;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    .bio-title {
      width: 100%;

      h2 {
        font-size: 1.2em;
        font-weight: bold;
        font-family: 'Noto Sans JP', 'Roboto', sans-serif;
      }
    }

    & > .bio-story {
      margin: 1rem;

      .stage {
        display: grid;
        grid-template-columns: 16px auto;
        grid-gap: 1rem;
        
        .point {
          display: grid;
          grid-template-rows: max-content auto;

          .circle {
            @include flex-center();
            border: 1px solid currentColor;
            border-radius: 100px;
            width: 16px;
            height: 16px;
            padding: 2px;
            margin: 0 auto;

            &.finish {
              padding: 0;
              background: currentColor;
            }
            &.sub {
              width: 12px;
              height: 12px;
            }

            .middle {
              width: 100%;
              height: 100%;
              background: currentColor;
              border-radius: 100px;
            }
          }
          .line {
            border: 1px solid currentColor;
            height: 100%;
            width: 0;
            margin: 0 auto;
          }
        }
        .description {
          margin-top: -6px;
          
          h4 {
            color: $stageTitleColor;
            font-weight: bold;
            font-family: 'Noto Sans JP', sans-serif;
          }
          .sub-title {
            color: $white;
            font-weight: 400;
          }
          span {
            color: $stageDurationColor;
            font-family: 'Noto Sans JP', sans-serif;
            font-weight: 100;
            margin-bottom: 1rem;
          }
          p {
            padding-bottom: 1rem;  
            margin: 6px 0;
            font-family: 'Noto Sans JP', sans-serif;
          }
        }
      }
    }

    & > .bio-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 6px 18px;

      & > .resume {
        border: 1px solid $white;
        border-radius: 2px;
        text-align: center;
        padding: 8px 16px;

        & > a {
          color: $white;
          text-decoration: none;
          font-weight: bold;
          font-family: 'Noto Sans JP', sans-serif;
        }

        &:hover {
          cursor: pointer;
          background: $white;
          
          & > a {
            color: $black;
          }
        }
      }
    }
  }

  .stages-preview {
    position: fixed;
    top: 50%;
    right: 1rem;
    display: grid;
    grid-template-columns: 1px auto;
    grid-gap: 1rem;
    z-index: 100;
    opacity: 0.7;
    
    .stage-line-container {
      @include flex-center();
      height: 100%;

      .line {
        width: 0;
        height: 80%;
        border: 1px solid $stageTitleColor;
      }
    }
    .stages {
      @include flex-column();
      align-items: flex-start;
      
      span {
        padding: 0.4rem 0;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
        color: $stageTitleColor;
      }
    }
  }

  .photos {
    @include flex-center();
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 2rem 0;
    margin: 2rem auto 6rem auto;

    .left, .right {
      position: relative;
      max-width: 200px;
      width: 20vw;
      max-height: 200px;
      height: 20vw;
    
      .img {
        position: absolute;
        filter: drop-shadow(0px 4px 10px #30627C);
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
    .left {
      .sport1 {
        left: 25%;
        bottom: 50%;
        z-index: 1;
        background: url('../../assets/images/sports/sport1.webp') no-repeat center 100%/cover;
      }
      .sport2 {
        left: 75%;
        top: 25%;
        background: url('../../assets/images/sports/sport2.webp') no-repeat center 100%/cover;
      }
    }
    .right {
      .sport3 {
        right: 75%;
        bottom: 25%;
        z-index: 1;
        background: url('../../assets/images/sports/sport3.webp') no-repeat center 100%/cover;
      }
      .sport4 {
        right: 25%;
        top: 50%;
        background: url('../../assets/images/sports/sport4.webp') no-repeat center 100%/cover;
      }
    }
  }

  .hobbies-section {
    min-height: unset;
    padding: 1rem;
  }

  .hobbies {
    @include flex-center();
    width: 100%;

    .title-bar {
      @include flex-center();
      width: 200px;
      background: $calmWhite;
      padding: 1rem 0;
      border-radius: 100px;

      span {
        font-family: Noto Sans JP, sans-serif;
      }
    }
    .line {
      width: 80%;
      border: 1px solid $calmWhite;
    }
  }

  .flex-end {
    @include flex-center();
    justify-content: flex-end;
  }
  .coffee {
    margin: 4rem 0;
    width: 80%;
    border-radius: 20px;
    border: 2px solid $stageTitleColor;

    img {
      width: 100%;
      border-radius: 20px;
      transform: translateX(-30px) translateY(-20px);
    }
  }

  @media screen and (max-width: 1100px) {
    .stages-preview {
      display: none;
    }
  }
</style>