<template>
  <navigation-buttons isPage="portfolio" />
  
  <section>
    <div class="section-entity">
      <projects />
    </div>

    <div class="section-entity">
      <div class="entity-container">
        <skills />
      </div>
    </div>

    <div class="section-entity">
      <about />
    </div>
  </section>

  <Footer></Footer>
</template>

<script>
import NavigationButtons from '../sections/NavigationButtons';
import Projects from '../sections/projects/Projects.vue';
import Skills from '../sections/skills/Skills.vue';
import About from '../sections/aboutme/About.vue';
import Footer from '../sections/Footer.vue';

export default {
  name: 'PortfolioPage',
  components: { 
    NavigationButtons,
    Projects,
    Skills,
    About,
    Footer
  }
}
</script>

<style lang="sass">
  $offWhite: rgba(255, 255, 255, 0.25)

  .section-entity
    padding: 1rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: stretch

  .entity-container
    height: fit-content
    border-radius: 20px
    background: $offWhite
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25)
    overflow: hidden

</style>